import store from '..';
import i18n from '../../i18n';
import { Literals, LOAD_LITERALS } from '../types/literals';

const setLanguage = (lang = 'en'): void => {
  localStorage.setItem('lang', lang);
  i18n(lang).then((literals: Literals) => {
    store.dispatch({
      type: LOAD_LITERALS,
      payload: literals,
    });
  });
};

export { setLanguage };
export default { setLanguage };
