const styles = {
  root: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 560,
  },
  textWrapper: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#3490ca !important',
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#3490ca !important',
    },
  },
  cssFocused: {},
  notchedOutline: {},
};

export default styles;
