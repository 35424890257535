import React, { ReactElement, useState } from 'react';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import VerificationForm from '../components/forms/verify';
import Notification from '../components/notification';

import { userVerify } from '../store/actions/verify';
import { NotificationShowAction, NotificationClearAction } from '../store/types/notification';
import { notificationShow, notificationClear } from '../store/actions/notification';
import { RootState } from '../store/reducers';

type NotifyAction = (dispatch: Dispatch<NotificationShowAction>) => void;
type NotifyClearAction = (dispatch: Dispatch<NotificationClearAction>) => void

const useStyles = makeStyles({
  main: {
    padding: '1rem',
    width: '100%',
    margin: '0 auto',
    maxWidth: 560,
  },
  header: {
    fontFamily: '"Muli", Arial',
    fontWeight: 800,
    fontSize: '2.5rem',
    marginTop: '2rem',
    color: '#333',
    textAlign: 'center',
  },
  body: {
    fontSize: '1rem',
    fontFamily: '"Muli", Arial',
    lineHeight: 1.5,
    marginTop: '2rem',
    color: '#333',
    textAlign: 'center',
  },
});

const VerifyPage = (): ReactElement => {
  const literals = useSelector((state: RootState) => state.literals);
  const errorMessage = useSelector((state: RootState) => state.verify?.error?.message);
  const open = useSelector((state: RootState) => state.notification?.open);
  const variant = useSelector((state: RootState) => state.notification?.variant);
  const message = useSelector((state: RootState) => state.notification?.message);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();

  const notify = (msg: string, vrnt: 'success' | 'error'): NotifyAction => (
    dispatch(notificationShow(msg, vrnt))
  );

  const clear = (): NotifyClearAction => dispatch(notificationClear());

  const verifyUserIp = async (): Promise<void> => {
    const lang = localStorage.getItem('lang') || 'en';

    try {
      if (email) {
        const response = await userVerify(email, lang)(dispatch);

        if (!response.success) {
          throw Error('Failed to verify IP address.');
        }

        notify(literals.successMessage, 'success');
      }
    } catch (error) {
      notify(literals.errorMessage, 'error');
    }
  };

  const handleChangeEmail = (newEmail: string): void => setEmail(newEmail);

  return (
    <>
      <div className={classes.main}>
        <div className={classes.header}>{literals.hello}</div>
        <div className={classes.body}>
          {literals.content1}
          <br />
          {literals.content2}
          <br />
          {literals.content3}
        </div>
      </div>
      <VerificationForm
        onClick={verifyUserIp}
        error={errorMessage}
        onChange={handleChangeEmail}
      />
      <Notification
        open={open}
        message={message}
        variant={variant}
        onClose={clear}
      />
    </>
  );
};

export default VerifyPage;
