import { Dispatch } from 'redux';

import config from '../../config';
import {
  VerifyActionTypes,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
} from '../types/verify';

const userVerify = (email: string, lang: string) => (
  async (dispatch: Dispatch<VerifyActionTypes>): Promise<{ success: boolean}> => {
    try {
      await dispatch({ type: VERIFY_REQUEST });
      const response = await fetch(`${config.api.url}/users/verify`, {
        method: 'POST',
        body: JSON.stringify({
          email,
          lang,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      await dispatch({
        type: VERIFY_SUCCESS,
        payload: data,
      });

      return {
        success: true,
      };
    } catch (error) {
      await dispatch({
        type: VERIFY_FAILURE,
        payload: error,
      });

      return {
        success: false,
      };
    }
  }
);

export { userVerify };
export default { userVerify };
