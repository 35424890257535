import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  img: {
    height: '3rem',

    '@media (min-width: 600px)': {
      height: '6rem',
      margin: '0 auto',
    },
  },
});

export default (): ReactElement => {
  const classes = useStyles();

  return (
    <img
      className={classes.img}
      alt="Streamframe"
      src="https://static.streamframe.com/images/logo-full.svg"
    />
  );
};
