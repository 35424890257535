import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './logo';

const useStyles = makeStyles({
  menu: {
    textAlign: 'center',
  },
});

export const MenuBar = (): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.menu}>
      <a href="/">
        <Logo />
      </a>
    </div>
  );
};

export default MenuBar;
