import {
  Notification,
  NOTIFICATION_SHOW,
  NOTIFICATION_CLEAR,
  NotificationActionTypes,
} from '../types/notification';

const initialState: Notification = {
  message: '',
  open: false,
  variant: 'success',
};

export default (state = initialState, action: NotificationActionTypes): Notification => {
  switch (action.type) {
    case NOTIFICATION_SHOW: {
      const { message, variant } = action.payload;
      return {
        ...state,
        open: true,
        message,
        variant,
      };
    }
    case NOTIFICATION_CLEAR: {
      return {
        ...state,
        open: false,
        message: '',
      };
    }
    default: {
      return state;
    }
  }
};
