import emails from './emails';

export const api = {
  url: process.env.REACT_APP_API_URL || 'https://api.streamframe.com',
};

export const users = {
  url: process.env.REACT_APP_USERS_URL || `${api.url}/users`,
};

export const cdn = {
  url: process.env.REACT_APP_CDN_URL || 'https://static.streamframe.com',
};

export const federation = {
  url: process.env.REACT_APP_FEDERATION_URL || 'https://login.streamframe.com',
};

export const config = {
  emails,
  api,
  cdn,
  federation,
  users,
};

export default config;
