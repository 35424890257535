import React, {
  ReactElement,
  ChangeEvent,
  FormEvent,
  useState,
} from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

import SubmitButton from '../../buttons/submit';
import styles from './styles';

type Props = {
  error: string | undefined;
  onClick: Function;
  onChange: (email: string) => void;
};

const useStyles = makeStyles(styles);

export const VerifyForm = (props: Props): ReactElement => {
  const {
    error = '',
    onClick = (): void => { /**/ },
    onChange = (): void => { /**/ },
  } = props;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value } = event.target;
    setEmail(value);
    onChange(value);
  };

  const handleValidateEmail = (): boolean => {
    const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    if (!reg.test(email)) {
      return false;
    }

    return true;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (handleValidateEmail()) {
      setLoading(true);

      onClick().then(() => setLoading(false));
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <div className={classes.textWrapper}>
        <TextField
          required
          error={error !== null && error !== undefined && error !== ''}
          helperText={error || ''}
          onBlur={handleValidateEmail}
          onChange={handleChange}
          id="outlined-email-input"
          label="Email"
          value={email}
          type="email"
          name="email"
          autoComplete="email"
          margin="normal"
          variant="outlined"
          fullWidth
          color="primary"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
      </div>
      <SubmitButton loading={loading} />
    </form>
  );
};

export default VerifyForm;
