import {
  Verify,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  VerifyActionTypes,
} from '../types/verify';

const initialState: Verify = {
  user: null,
  error: null,
  loading: false,
  loaded: false,
};

export default (state = initialState, action: VerifyActionTypes): Verify => {
  switch (action.type) {
    case VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        loaded: true,
        user: action.payload,
      };
    case VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    default: { return state; }
  }
};
