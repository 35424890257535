import { Dispatch } from 'redux';

import {
  NOTIFICATION_SHOW,
  NOTIFICATION_CLEAR,
  NotificationShowAction,
  NotificationClearAction,
} from '../types/notification';

const notificationShow = (message: string, variant: 'success' | 'error') => (
  (dispatch: Dispatch<NotificationShowAction>): void => {
    dispatch({
      type: NOTIFICATION_SHOW,
      payload: {
        message,
        variant,
      },
    });
  }
);

const notificationClear = () => (dispatch: Dispatch<NotificationClearAction>): void => {
  dispatch({ type: NOTIFICATION_CLEAR });
};

export { notificationShow, notificationClear };
export default { notificationShow, notificationClear };
