import React, { ReactElement } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import VerifyPage from './pages/verify';
import EnablePage from './pages/enable';
import SuccessPage from './pages/success';

import MenuBar from './components/menuBar';
import Footer from './components/footer';

const Root = (): ReactElement => (
  <main>
    <MenuBar />
    <Router>
      <Switch>
        <Route path="/success" component={SuccessPage} />
        <Route exact path="/enable" component={EnablePage} />
        <Route exact path="/" component={VerifyPage} />
        <Redirect to="/" />
      </Switch>
    </Router>
    <div style={{ flexGrow: 1 }} />
    <Footer />
  </main>
);

export default Root;
