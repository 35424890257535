import {
  Literals,
  LOAD_LITERALS,
  LiteralsActionTypes,
} from '../types/literals';

const initialState = {};

export default (state = initialState, action: LiteralsActionTypes): Literals => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_LITERALS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
