import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';

import reducers from './reducers';

const isDevelopment = process.env.NODE_ENV === 'development';
const isDebug = !!process.env.REACT_APP_DEBUG;

const middleware = [promise, thunk];

if (isDevelopment || isDebug) {
  middleware.push(createLogger({
    collapsed: true,
  }));
}

export default createStore(reducers, applyMiddleware(...middleware));
