import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/reducers';
import emails from '../../config/emails';
import LanguageSelector from '../language-selector';

import classes from './styles.module.css';
import config from '../../config';

export const Footer = (): ReactElement => {
  const literals = useSelector((state: RootState) => state.literals);

  return (
    <footer className={classes.footer}>
      <LanguageSelector />
      <div className={classes.divider} />
      <a
        href={`${config.federation.url}/login`}
        className={classes.link}
      >
        {literals.signin}
      </a>
      <a
        href="https://www.streamframe.com"
        className={classes.link}
      >
        {literals.about}
      </a>
      <a
        href={`mailto:${emails.support}`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        {literals.help}
      </a>
      <span className={classes.copyright}>Streamframe © 2021</span>
    </footer>
  );
};

export default Footer;
