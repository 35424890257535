export type Literals = {
  [key: string]: string;
};

export const LOAD_LITERALS = 'LOAD_LITERALS';

export type LoadLiteralsAction = {
  type: typeof LOAD_LITERALS;
  payload: Literals;
};

export type LiteralsActionTypes = LoadLiteralsAction;

export default {
  LOAD_LITERALS,
};
