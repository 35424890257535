export type Notification = {
  message: string;
  variant: 'success' | 'error';
  open: boolean;
}

export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

export type NotificationShowAction = {
  type: typeof NOTIFICATION_SHOW;
  payload: {
    message: string;
    variant: 'success' | 'error';
  };
};

export type NotificationClearAction = {
  type: typeof NOTIFICATION_CLEAR;
};

export type NotificationActionTypes = NotificationShowAction |
  NotificationClearAction;

export default {
  NOTIFICATION_SHOW,
  NOTIFICATION_CLEAR,
};
