import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';

import config from '../../config';
import defaultLiterals from '../../i18n/en.json';
import { RootState } from '../../store/reducers';

import classes from './styles.module.css';

export const Enable = (): ReactElement => {
  const literals = useSelector((state: RootState) => state.literals);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [hasError, setHasError] = useState<Error | null>(null);
  const fetching = useRef<boolean>(false);

  useEffect(() => {
    const handleEnable = async (t?: string | null): Promise<void> => {
      if (!fetching.current) {
        try {
          if (!t) {
            return;
          }

          const defaultError = 'Invalid or Expired token';
          fetching.current = true;
          const response = await fetch(`${config.api.url}/users/verify`, {
            method: 'PUT',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({ token: t }),
          });

          if (!response.ok) {
            throw new Error(defaultError);
          }

          const json = await response.json();

          if (json.status === 'error') {
            throw new Error(defaultError);
          }

          fetching.current = false;
        } catch (error) {
          fetching.current = false;
          setHasError(error);
        } finally {
          setLoading(false);
        }
      }
    };

    if (literals) {
      const { location } = window;
      const params = new URLSearchParams(location.search);
      const t = params.get('token');

      if (t) {
        setToken(t);
      }

      handleEnable(t);
    }
  }, [literals]);

  if (loading) {
    return <div className={classes.loading}>Loading</div>;
  }

  if (!token) {
    window.location.href = '/';
    return <></>;
  }

  return (
    <div className={classes.wrapper}>
      <h1 className={classes.heading}>
        {hasError ? (
          <>{literals.failure || defaultLiterals.failure}</>
        ) : (
          <>
            {literals.authorized || defaultLiterals.authorized}
            <br />
            {literals.login}
            <br />
            <a href={config.federation.url}>{config.federation.url}</a>
          </>
        )}
      </h1>
    </div>
  );
};

export default Enable;
