import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { RootState } from '../store/reducers';

const useStyles = makeStyles({
  wrapper: {
    fontFamily: '"Muli", Arial',
    textAlign: 'center',
    lineHeight: '2rem',
    marginTop: '5rem',
    marginBottom: '5rem',
  },
  heading: {
    marginTop: '3rem',
  },
  subheading: {
    marginTop: '3rem',
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  link: {
    textDecoration: 'none',
    color: '#3490ca',
  },
});

export const Success = (): ReactElement => {
  const literals = useSelector((state: RootState) => state.literals);
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <h1 className={classes.heading}>{literals.success}</h1>
      <p className={classes.subheading}>
        {literals.authorized}
        <br />
        {literals.login}
        <div>
          <a href="https://login.streamframe.com" className={classes.link}>
            https://login.streamframe.com
          </a>
        </div>
      </p>
    </div>
  );
};

export default Success;
