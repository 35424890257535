import { combineReducers } from 'redux';

import verify from './verify';
import literals from './literals';
import notification from './notification';

const reducer = combineReducers({
  verify,
  literals,
  notification,
});

export type RootState = ReturnType<typeof reducer>
export default reducer;
