export type Verify = {
  user: any;
  error: Error | null;
  loading: boolean;
  loaded: boolean;
};

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';

export interface VerifyRequestAction {
  type: typeof VERIFY_REQUEST;
}

export interface VerifySuccessAction {
  type: typeof VERIFY_SUCCESS;
  payload: string;
}

export interface VerifyFailureAction {
  type: typeof VERIFY_FAILURE;
  payload: Error;
}

export type VerifyActionTypes = VerifyRequestAction |
  VerifySuccessAction |
  VerifyFailureAction;

export default {
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
};
