import React, { ReactElement } from 'react';

import { setLanguage } from '../../store/actions/literals';

import classes from './styles.module.css';

const locales = {
  en: 'English (US)',
  jp: '日本語',
};

export const LanguageSelector = (): ReactElement => {
  const current = localStorage.getItem('lang') || 'en';

  const handleChangeLocale = (locale: string) => (): void => setLanguage(locale);

  return (
    <ul className={classes.list}>
      {Object.keys(locales).map((locale) => (
        <li key={locale} className={classes.listItem}>
          <button
            type="button"
            disabled={locale === current}
            className={classes.listItemButton}
            onClick={handleChangeLocale(locale)}
          >
            {locales[locale as keyof typeof locales]}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default LanguageSelector;
