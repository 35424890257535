import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import { RootState } from '../../store/reducers';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  button: {
    width: '100%',
    height: '3rem',
    backgroundColor: '#3490ca',

    '&:hover': {
      backgroundColor: '#0c79bf',
    },
  },
  progress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export default (props: { loading: boolean }): ReactElement => {
  const { loading = false } = props;
  const literals = useSelector((state: RootState) => state.literals);
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={loading}
        className={classes.button}
      >
        {literals.submit || 'Submit'}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={classes.progress}
        />
      )}
    </div>
  );
};
