import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';

import App from './app';
import store from './store';

import { setLanguage } from './store/actions/literals';

import './styles/index.css';

setLanguage(localStorage.getItem('lang') || 'en');

const rootEl = document.getElementById('root');
if (rootEl) {
  ReactDOM.render(
    <StrictMode>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </StrictMode>,
    rootEl,
  );
}
